"use strict";

var CustomForm = Core.ShareableModel.extend({
    urlRoot: Core.contextRoot + '/form',
    editGroupPermission: 'admin_forms',

    setFromTemplate: function(template) {
        this.set({
            fromTemplateId: template.id,
            title: template.get('title'),
            description: template.get('description'),
            instructions: template.get('instructions'),
            groupId: template.get('groupId'),
            requiredFields: template.get('requiredFields'),
            fields: template.get('fields'),
        }, {silent: true});
    },

    shareByEmail: function(emailAddress, message, shareInternalInfo, pdf) {
        var data = { to: emailAddress, message: message };
        if (shareInternalInfo) data.internal = true;
        if (pdf) data.pdf = true;
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/share/email',
            type: 'POST',
            data: data
        });

    },

});

var CustomForms = Backbone.Collection.extend({
    model: CustomForm,

    url: function() {
        // Build query from filter parameters
        var url;
        var params = { };

        if (this.filterParams.limit) params.limit = this.filterParams.limit;

        if (this.searchQuery) {
            url = Core.contextRoot + '/form/search';
            params.q = this.searchQuery;
        } else {
            url = Core.contextRoot + '/form' + (this.filterParams.path ? '/' + this.filterParams.path : '');
            if (this.filterParams.path && this.filterParams.path == 'templates') {
                if (this.filterParams.includeDrafts) params.drafts = true;
            }
        }
        var paramsStr = $.param(params);
        if (paramsStr.length > 0)
            url += '?' + paramsStr;
        return url;
    },

    initialize: function(models, options) {
        this.filterParams = {};
        if (options && options.filterParams)
            _(this.filterParams).extend(options.filterParams);
        else if ('forms.filterParams' in sessionStorage)
            this.filterParams = JSON.parse(sessionStorage['forms.filterParams']);
    },

    saveFilter: function() {
        sessionStorage['forms.filterParams'] = JSON.stringify(this.filterParams);
    }
});

