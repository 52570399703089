"use strict";

var Reservation = Core.ShareableModel.extend({
    urlRoot: Core.contextRoot + '/reservation',
    editGroupPermission: 'admin_reservations',

    defaults: function() {
        return {
            uuid: Core.randomUUID(),
            state: 'PUBLISHED',
            documents: [],
            labels: [],
        };
    },

    emptyTemplate: function() {
        return {
            description: '',
            documents: [],
            excludedFields: [],
            customFields: [],
            internalFields: [],
            requiredFields: []
        }
    },

    setFromTemplate: function (template, previousTemplate) {
        var attributes = $.extend(true, {}, template.attributes);
        attributes.fromTemplateId = template.attributes.id;
        delete attributes.title;
        delete attributes.id;
        delete attributes.uuid;
        delete attributes.version;
        delete attributes.lastModified;
        delete attributes.creatorId;
        delete attributes.creationTimeStamp;
        delete attributes.state;
        delete attributes.template;
        delete attributes.events;

        if (attributes.documents)
            _(attributes.documents).each(function (document) { delete document.id; delete document.uuid; });

        // Retain values from previous template
        var current = this.attributes;
        var previous = previousTemplate ? previousTemplate.attributes : this.emptyTemplate();

        if (current.startTime != previous.startTime) delete attributes.startTime;
        if (current.endTime != previous.endTime) delete attributes.endTime;
        if (current.customerId != previous.customerId) delete attributes.customerId;
        if (current.customerProjectId != previous.customerProjectId) delete attributes.customerProjectId;
        if (current.assetId != previous.assetId) delete attributes.assetId;
        if (current.activityId != previous.activityId) delete attributes.activityId;

        // Don't change group if we don't have permission in that group
        if (!Core.client.hasGroupPermission(attributes.groupId, 'create_reservations')) delete attributes.groupId;
        // Retain current group if template is from parent group
        if (template.isValidForGroup(current.groupId)) delete attributes.groupId;

        var contact = current.contact;
        var prevContact = previous.contact;
        if (contact && prevContact) {
            if (contact.name != prevContact.name || contact.emailAddress != prevContact.emailAddress || contact.phoneNo != prevContact.phoneNo)
                delete attributes.contact;
        } else if (contact != prevContact)
            delete attributes.contact;

        if (previousTemplate && current.description != previous.description) delete attributes.description;

        if (!this.savedCustomFields) this.savedCustomFields = [];
        _(this.get('customFields')).each(function (field) {
            var existingField = _(this.savedCustomFields).findWhere({name: field.name});
            if (existingField)
                existingField.value = field.value;
            else
                this.savedCustomFields.push(_(field).clone());
        }, this);
        if (attributes.customFields && current.customFields) {
            _(current.customFields).each(function (prefield) {
                var postfield = _(attributes.customFields).findWhere({name: prefield.name});
                if (!postfield) postfield = _(attributes.customFields).findWhere({label: prefield.label});
                if (postfield) postfield.value = prefield.value;
            }, this);
        }
        _(this.savedCustomFields).each(function (field) {
            if (!field.value) return;
            var postfield = _(attributes.customFields).findWhere({name: field.name});
            if (!postfield) postfield = _(attributes.customFields).findWhere({label: field.label});
            if (postfield && !postfield.value) postfield.value = field.value;
        }, this);

        if (attributes.assets)
            _(attributes.assets).each(function (assetreservation) {
                delete assetreservation.id;
            });

        if (current.documents && current.documents.length > 0 && (!attributes.documents || attributes.documents.length == 0) && (!previous.documents || previous.documents.length == 0))
            attributes.documents = current.documents;

        this.set(attributes);
    },

    isDispatchable: function() {
        return Core.client.hasGroupPermission(this.get('groupId'), 'dispatch_assets');
    },

    state: function() {
        if (this.get('state') == 'DRAFT') return 'DRAFT';
        var assigned = this.get('assets') && this.get('assets').length > 0;
        if (assigned) _(this.get('assets')).each(function (assetReservation) { if (!assetReservation.assetId) assigned = false; });
        if (!assigned) return 'PUBLISHED';
        var now = new Date().getTime();
        if (this.get('endTime') && now >= this.get('endTime')) return 'FINISHED';
        if (this.get('startTime') && now >= this.get('startTime')) return this.get('endTime') ? 'ACTIVE' : 'FINISHED';
        return 'RESERVED';
    },

    isPublished: function() {
        var state = this.get('state');
        return (state && state != 'DRAFT' && state != 'CLOSED' && state != 'CANCELED');
    },

    sortTime: function() {
        if (this.get('startTime')) return this.get('startTime');
        if (this.get('endTime')) return this.get('endTime');
        return null;
    },

    sortTimeIncludingUntimed: function() {
        var sortTime = this.sortTime();
        if (sortTime) return sortTime;
        var state = this.get('state');
        if (state == 'FINISHED' || state == 'CANCELED' || state == 'CLOSED') return 0;
        if (state == 'ACTIVE') return new Date().getTime();
        return this.get('creationTimeStamp') * 10; // always top of the list but still sorted according to creation time
    },

    isLate: function() {
        var state = this.get('state');
        var startTime = this.get('startTime');
        var endTime = this.get('endTime');
        var allDay = this.get('allDay');
        if (state == 'INITIAL' || state == 'DRAFT' || state == 'FINISHED' || state == 'CANCELED' || state == 'CLOSED')
            return false;
        if (endTime && new Date().getTime() > endTime + (allDay ? 24*3600000 : 0))
            return true;
        //if (!allDay && !endTime && startTime && duration && new Date().getTime() > startTime + duration*60000)
        //    return true;
        return false;
    },

    getAssetReservation: function (assetId) {
        if (assetId && typeof assetId == 'object') assetId = assetId.id;
        if (!assetId && this.get('assets') && this.get('assets').length == 1) return this.get('assets')[0];
        return _(this.get('assets')).findWhere({assetId: assetId});
    },

    titleExtended: function() {
        var title = this.get('title');
        if (!title) {
            title = '';
            if (this.get('customerName'))
                title += this.get('customerName');
            if (this.get('customerProjectName'))
                title += ': ' + this.get('customerProjectName');
            var assets = this.assetsDescription();
            if (assets) title += (title.length > 0 ? ': ' : '') + assets;
        }
        return title;
    },

    assetsDescription: function() {
        var assets = this.get('assets');
        if (assets && assets.length == 1) {
            return (assets[0].assetNo ? assets[0].assetNo + ' - ' : '') + (assets[0].assetName || assets[0].assetTypeName || '');
        } else if (assets && assets.length > 1) {
            var countByType = {};
            _(assets).each(function (asset) {
                if (!asset.assetTypeName) return;
                if (!countByType[asset.assetTypeName])
                    countByType[asset.assetTypeName] = 1;
                else
                    countByType[asset.assetTypeName]++;
            });
            var entries = [];
            _(Object.keys(countByType)).each(function (type) {
                entries.push(countByType[type] + ' ' + type);
            });
            return entries.join(', ');
        }

    },

    getDuration: function() {
        var duration = 0;
        if (this.get('endTime') && this.get('startTime')) duration = (this.get('endTime') - this.get('startTime')) / 60000;
        return duration;
    },

    exceedsMaxDuration: function() {
        var group = Core.client.groups.get(this.get('groupId'));
        if (!group) return false;
        var maxDuration = group.configuration('reservation_max_duration');
        return (maxDuration && this.getDuration() > maxDuration);

    },

    getAdvance: function() {
        var advance = 0;
        if (this.get('startTime')) advance = (this.get('startTime') - new Date().getTime()) / 3600000;
        return advance;
    },

    exceedsMaxAdvance: function() {
        var group = Core.client.groups.get(this.get('groupId'));
        if (!group) return false;
        var maxAdvance = group.configuration('reservation_max_advance');
        return (maxAdvance && this.getAdvance() > maxAdvance);
    },

    belowMinAdvance: function() {
        var group = Core.client.groups.get(this.get('groupId'));
        if (!group) return false;
        if (this.get('startTime') && this.isPublished() && this.get('state') != 'FINISHED' && this.get('state') != 'CANCELED') {
            var minAdvance = group.configuration('reservation_min_advance');
            return (minAdvance && this.getAdvance() < minAdvance);
        }
    },

    attachDocument: function(documentId) {
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/document/' + documentId,
            type: 'PUT'
        });
    },

    detachDocument: function(documentId) {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/document/' + documentId,
            type: 'DELETE'
        }).success(function() {
            self.set({documents: _(self.get('documents')).filter(function(d) { return d.id != documentId && d.uuid != documentId })}, { silent: true });
            self.trigger('detachDocument', self);
        });
    },

    shareByEmail: function(emailAddress, message, shareInternalInfo, pdf, include) {
        var data = { to: emailAddress, message: message };
        if (shareInternalInfo) data.internal = true;
        if (pdf) data.pdf = true;
        if (typeof include == 'object')
            data.include = include.join(',');
        else if (include)
            data.include = include;
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/share/email',
            type: 'POST',
            data: data
        }).success(function (data) {
            self.attributes.events.push({
                type: 'SHARE',
                comment: emailAddress,
                userId: Core.client.me.get('id'),
                eventTimeStamp: new Date().getTime(),
                internal: true
            });
        });

    },

    publish: function() {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.get('uuid') + '/publish',
            type: 'PUT'
        }).success(function (data) {
            self.set(data);
        });
    },

    unpublish: function() {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.get('uuid') + '/unpublish',
            type: 'PUT'
        }).success(function (data) {
            self.set(data);
        });
    },

    close: function() {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.get('uuid') + '/close',
            type: 'PUT'
        }).success(function (data) {
            self.set(data);
        });
    },

    cancel: function() {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.get('uuid') + '/cancel',
            type: 'PUT'
        }).success(function (data) {
            self.set(data);
        });
    },

});

var Reservations = Backbone.Collection.extend({
    model: Reservation,

    url: function() {
        if (this.customUrl) return this.customUrl;

        // Build query from filter parameters
        var url;
        var params = { };

        if (this.filterParams.states) {
            if (typeof this.filterParams.states == 'object')
                params.states = this.filterParams.states.join(',');
            else
                params.states = this.filterParams.states;
        }

        if (this.searchQuery) {
            url = Core.contextRoot + '/reservation/search';
            params.q = this.searchQuery;
        } else {
            url = Core.contextRoot + '/reservation/list' + (this.filterParams.path ? '/'+this.filterParams.path : '');
            if (this.filterParams.path == 'templates') {
                url = Core.contextRoot + '/reservation/templates';
                if (this.filterParams.includeDrafts) params.drafts = true;
            } else {
                if (this.filterParams.includeUntimed) params.untimed = '';
                if (this.filterParams.from == null || typeof this.filterParams.from == 'undefined') {
                    var fromDate = new Date();
                    fromDate.setHours(0,0,0,0);
                    this.filterParams.from = fromDate.getTime();
                }
                params.from = this.filterParams.from;
                if (this.filterParams.to)
                    params.to = this.filterParams.to;
                if ('includeCurrent' in this.filterParams) {
                    if (this.filterParams.includeCurrent)
                        params.current = '';
                } else if (!this.filterParams.from && !this.filterParams.to)
                    params.current = '';
            }
            if (this.filterParams.params) _(params).extend(this.filterParams.params);
        }
        if (this.filterParams.limit) params.limit = this.filterParams.limit;
        if (this.filterParams.offset) params.offset = this.filterParams.offset;
        var paramsStr = $.param(params);
        if (paramsStr.length > 0) url += '?' + paramsStr;
        return url;
    },

    initialize: function(models, options) {
        this.filterParams = {
            path: 'all',
            states: ['DRAFT','PUBLISHED','RESERVED','ACTIVE','FINISHED','CANCELED'],
            includeCurrent: true,
            includeUntimed: true,
            from: null,
            to: null,
        };
        if (options && options.history)
            this.filterParams.states.push('CLOSED');
        if (options && options.url)
            this.customUrl = options.url;
        else if (options && options.filterParams)
            _(this.filterParams).extend(options.filterParams);
        else if ((!options || !options.temporary) && ('reservations.filterParams' in sessionStorage))
            this.filterParams = JSON.parse(sessionStorage['reservations.filterParams']);
        if (! 'path' in this.filterParams)
            this.filterParams.path = 'all';
    },

    saveFilter: function() {
        sessionStorage['reservations.filterParams'] = JSON.stringify(this.filterParams);
    },

    loadFilter: function() {
        this.filterParams = JSON.parse(sessionStorage['reservations.filterParams']);
    },

    forEachRelevantTemplate: function(callback, context) {
        var limitToPrimaryGroup = Core.client.me.get('shareAllWithPrimaryGroup') && this.filter(function (template) {
                return template.isValidForGroup(Core.client.me.get('primaryGroupId')) && Core.client.hasPermissionInGroupOrDescendant(template.get('groupId'), 'create_reservations');
            }).length > 0;
        return this.each(function (template) {
            var defaultTemplate = Core.client.primaryGroup() && Core.client.primaryGroup().getOrGetInParentGroup('defaultReservationTemplateId') == template.id;
            if (template.get('groupId') && !Core.client.hasPermissionInGroupOrDescendant(template.get('groupId'), 'create_reservations')) return;
            if (limitToPrimaryGroup && !template.isValidForGroup(Core.client.me.get('primaryGroupId'))) return;
            callback.apply(context, [template, defaultTemplate]);
        });
    },

    findByAssetId: function(assetId) {
        return this.filter(function (reservation) {
            return _(reservation.get('assets')).findWhere({ assetId: assetId });
        });
    },

});

Reservations.filterCollection = function(filter, collection) {
    var filter = _(filter).clone();
    if (!filter.list) filter.list = 'all';
    var userId;
    var assetId;
    var assetTypeId;
    var label;
    if (filter.list == 'mine') {
        userId = Core.client.me.id;
        delete filter.where;
    } else if (filter.list.indexOf('user-') == 0) {
        userId = parseInt(filter.list.replace('user-', ''));
        delete filter.where;
    } else if (filter.list.indexOf('label-') == 0) {
        label = filter.list.replace('label-', '');
        delete filter.where;
    } else if (filter.list.indexOf('group-') == 0) {
        filter.where = { groupId: parseInt(filter.list.replace('group-','')) };
    } else if (filter.list.indexOf('customer-') == 0) {
        filter.where = { customerId: parseInt(filter.list.replace('customer-', '')) };
    } else if (filter.list.indexOf('asset-') == 0) {
        assetId = parseInt(filter.list.replace('asset-', ''));
        if (filter.where) delete filter.where.assetId;
    } else if (filter.list.indexOf('assettype-') == 0) {
        assetTypeId = parseInt(filter.list.replace('assettype-', ''));
    } else if (filter.list == 'all' || filter.list == 'unassigned') {
        delete filter.where;
    } else {
        console.error('Unknown filter selection ' + filter.list);
    }

    var filteredCollection = new Reservations(filter.where ? collection.where(filter.where) : collection.models);

    if (userId) {
        filteredCollection.reset(filteredCollection.where({creatorId: userId}), {silent: true});
    } else if (label) {
        filteredCollection.reset(filteredCollection.filter(function (model) {
            return model.get('labels') && model.get('labels').indexOf(label) >= 0;
        }, this), { silent: true });
    } else if (filter.list == 'unassigned') {
        filteredCollection.reset(filteredCollection.filter(function (model) {
            var allAssigned = true;
            _(model.get('assets')).each(function (assetReservation) {
                if (!assetReservation.assetId) allAssigned = false;
            });
            return allAssigned;
        }, this), { silent: true });
    }

    // filter out deletes and state changes from updates
    filteredCollection.reset(filteredCollection.filter(function (model) {
        if (assetId && !_(model.get('assets')).findWhere({assetId: assetId})) return false;
        if (assetTypeId && !_(model.get('assets')).findWhere({assetTypeId: assetTypeId})) return false;
        if (model.get('state') && collection.filterParams && collection.filterParams.states && collection.filterParams.states.indexOf(model.get('state')) < 0) return false;
        return !model.get('deleted');
    }), { silent: true });
    return filteredCollection;

};

