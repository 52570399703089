"use strict";

var InvoiceTodo = Backbone.Model.extend({

    initialize: function(models, options) {
        this.filterParams = {};
        this.path = 'todo';
        if (options && options.path) this.path = options.path;
    },

    url: function() {
        if (this.customUrl)
            return this.customUrl;

        // Build query from filter parameters
        var url;
        var params = { };
        url = Core.contextRoot + '/invoice/' + this.path;
        if (this.filterParams.from) params.from = this.filterParams.from;
        if (this.filterParams.to) params.to = this.filterParams.to;
        if (this.filterParams.states) params.states = this.filterParams.states.join(',');
        if (this.filterParams.customerIds) params.customers = this.filterParams.customerIds.join(',');
        if (this.filterParams.projectIds) params.projects = this.filterParams.projectIds.join(',');
        if (this.filterParams.activityIds) params.activities = this.filterParams.activityIds.join(',');
        if (this.filterParams.userIds) params.users = this.filterParams.userIds.join(',');
        if (this.filterParams.groupIds) params.groups = this.filterParams.groupIds.join(',');
        if (this.filterParams.onlyAttested) params.only_attested = this.filterParams.onlyAttested;
        var paramsStr = $.param(params);
        if (paramsStr.length > 0) url += '?' + paramsStr;
        return url;
    },

    filteredByActivityId: function(activityId) {
        var filtered = this.clone();
        var customers = [];
        _(this.get('customers')).each(function (customerTodo) {
            customerTodo = InvoiceTodo.consolidateTodoByActivityId(_(customerTodo).clone(), activityId);
            if (customerTodo) customers.push(customerTodo);
        }, this);
        filtered.set('customers', customers);
        return filtered;
    },

});

InvoiceTodo.consolidateTodoByActivityId = function(todo, activityId) {
    todo.activities = _(todo.activities).where({id: activityId});
    if (todo.activities.length == 1) {
        if (todo.customerProjects) {
            var projects = [];
            _(todo.customerProjects).each(function (projectTodo) {
                projectTodo = this.consolidateTodoByActivityId(_(projectTodo).clone(), activityId);
                if (projectTodo) projects.push(projectTodo)
            }, this);
            todo.customerProjects = projects;
        }
        InvoiceTodo.copyFromTo(todo.activities[0], todo);
        delete todo.activities;
        return todo;
    }
};

InvoiceTodo.copyFromTo = function(source, destination) {
    destination.jobIds = source.jobIds;
    destination.reportIds = source.reportIds;
    destination.currency = source.currency;
    destination.endTime = source.endTime;
    destination.finished = source.finished;
    destination.price = source.price;
    destination.priceComplete = source.priceComplete;
    destination.startTime = source.startTime;
};

var Invoice = Backbone.Model.extend({
    urlRoot: Core.contextRoot + '/invoice',

    postToConnect: function() {
        var self = this;
        return $.ajax({
            url: '/connect/invoice',
            type: 'POST',
            data: JSON.stringify(this.toJSON()),
            dataType: 'json',
            contentType: 'application/json'
        }).success(function(data) {
            self.set(data);
        });
    },

    destroyByUuid: function() {
        return $.ajax({
            url: Core.contextRoot + '/invoice/' + this.get('uuid'),
            type: 'DELETE'
        });
    },

    sumAmountsByUnit: function(unit, includeAddOn, requireWorkHours) {
        var sum = 0;
        if (this.get('items') && this.get('items').length > 0) {
            _(this.get('items')).each(function (item) {
                if (!item) return;
                if (!includeAddOn && item.addOn) return;
                if (requireWorkHours && !item.workHours) return;
                if (item.unit == unit && item.amount)
                    sum += item.amount;
            }, this);
        }
        return sum;
    },

    sumInvoicedAmountsByUnit: function(unit, includeAddOn, requireWorkHours) {
        var sum = 0;
        if (this.get('items') && this.get('items').length > 0) {
            _(this.get('items')).each(function (item) {
                if (!item) return;
                if (!includeAddOn && item.addOn) return;
                if (requireWorkHours && !item.workHours) return;
                if (item.unit == unit && item.invoicedAmount)
                    sum += item.invoicedAmount;
            }, this);
        }
        return sum;
    },

    sumPrice: function(unit, requireWorkHours) {
        if (this.get('items') && this.get('items').length > 0) {
            var sum = 0;
            _(this.get('items')).each(function (item) {
                if (!item) return;
                if (requireWorkHours && !item.workHours) return;
                if (!unit || item.unit == unit) {
                    if (item.unit == 'MONEY') {
                        sum += 100*item.invoicedAmount;
                    } else {
                        sum += item.unitPrice*item.invoicedAmount;
                    }
                }
            }, this);
            return parseInt(Math.round(sum));
        } else
            return 0;
    },

    sumVat: function(unit) {
        if (this.get('items') && this.get('items').length > 0) {
            var sum = 0;
            _(this.get('items')).each(function (item) {
                if (!item) return;
                if (!unit || item.unit == unit) {
                    if (item.unit == 'MONEY') {
                        sum += 100*item.invoicedAmount * item.vat/100;
                    } else {
                        sum += item.unitPrice*item.invoicedAmount * item.vat/100;
                    }
                }
            }, this);
            return parseInt(Math.round(sum));
        } else
            return 0;
    },

    isAttested: function(ignoreNotFinished){
        var allAttested = true;
        this.get('items').forEach(function(item){
            allAttested &= this.isItemAttested(item) || (ignoreNotFinished ? !item.finished : false);
        },this)
        return allAttested;
    },

    isItemAttested: function(item){
        return (item.attested && item.workReportId != null) || item.workReportId == null;
    },

    isPriced: function(unit, requireWorkHours) {
        var items = this.get('items');
        if (items && items.length > 0) {
            for (var i = 0; i < items.length; i++) {
                var item = items[i];
                if (!item) return false;
                if (requireWorkHours && !item.workHours) continue;
                if ((!unit || unit == item.unit) && item.unit != 'MONEY' && item.invoicedAmount
                    && (typeof item.unitPrice == 'undefined' || item.unitPrice == null))
                    return false;
            }
            return true;
        } else
            return true;
    },

    addItem: function(item) {
        if (typeof item == 'undefined') {
            item = {
                unit: 'MONEY',
                finished: true,
                vat: this.mostCommonVat()
            };
        }
        this.get('items').push(item);
        this.trigger('change');
    },

    removeItem: function(theItem) {
        var items = this.get('items');
        this.set('items', _(items).without(theItem));
        console.log('remove item',theItem.itemId);
        if (theItem.workReportId) {
            this.set('workReportItemIds', _(this.get('workReportItemIds')).without(theItem.itemId));
            if (! _(this.get('items')).findWhere({workReportId: theItem.workReportId})) {
                console.log('remove report', theItem.workReportId);
                this.set('workReportIds', _(this.get('workReportIds')).without(theItem.workReportId));
            }
        } else {
            this.set('jobItemIds', _(this.get('jobItemIds')).without(theItem.itemId));
            if (! _(this.get('items')).findWhere({jobId: theItem.jobId})) {
                console.log('remove job', theItem.jobId);
                this.set('jobIds', _(this.get('jobIds')).without(theItem.jobId));
            }
        }
    },

    excludeItem: function(theItem, setOptions) {
        return this.excludeItems(function (item) { return item == theItem; }, setOptions);
    },

    excludeNotFinishedItems: function() {
        this.excludeItems(function (item) { return !item.finished; });
    },

    excludeNotAttestedItems: function() {
        var jobIds = [];
        var unattested = this.get('items').filter(function(item){return !item.attested && item.workReportId != null && item.finished});
        unattested.forEach(function(item){
            if (jobIds.indexOf(item.jobId) < 0)
                jobIds.push(item.jobId);
        })
        this.excludeItems(function (item) {
            return (!item.attested && item.workReportId != null && item.finished) || (item.jobId != null && jobIds.indexOf(item.jobId) >= 0) });
    },

    excludeNotPricedItems: function() {
        this.excludeItems(function (item) {
            return item.unit != 'MONEY' && item.invoicedAmount && (typeof item.unitPrice == 'undefined' || item.unitPrice == null);
        });
    },

    excludeItemsAfter: function(time) {
        console.log('exclude after', time);
        this.excludeItems(function (item) {
            if (!item.workReportId && !item.jobId) return;
            return item.deliveryDate && item.deliveryDate >= time;
        });
    },

    excludeItems: function(filter, setOptions) {
        var items = this.get('items');
        var lengthBefore = items.length;
        var removeItems = [];
        _(items).each(function(item) {
            if (filter(item)) removeItems.push(item);
        });
        _(removeItems).each(function (removeItem) {
            if (removeItem.invoicedAmount && removeItem.workReportId) {
                // we need to remove all items from this report because we cannot invoice single items from reports..
                items = _(items).reject(function (item) {
                    return (item.workReportId == removeItem.workReportId);
                });
            } else {
                // this item has no invoiced amount so we can safely just remove it from the invoice
                items = _(items).without(removeItem);
            }
        });
        this.set({items: items}, {silent: true});
        this.rebuildRelations(setOptions);
        var numRemoved = lengthBefore - items.length;
        console.log(numRemoved + ' items removed');
        return numRemoved;
    },

    rebuildRelations: function(setOptions) {
        var items = this.get('items');
        this.set({
            jobIds: _(_(_(items).filter(function (item) { return item.jobId; })).pluck('jobId')).uniq(),
            workReportIds: _(_(_(items).filter(function (item) { return item.workReportId; })).pluck('workReportId')).uniq(),
            orderIds: _(_(_(items).filter(function (item) { return item.orderId; })).pluck('orderId')).uniq(),
            jobItemIds: _(_(items).filter(function (item) { return item.itemId && !item.workReportId; })).pluck('itemId'),
            workReportItemIds: _(_(items).filter(function (item) { return item.itemId && item.workReportId; })).pluck('itemId'),
        }, setOptions);
    },

    mostCommonVat: function() {
        var vatcount = {};
        var maxcount = 0;
        var maxVat;
        _(this.get('items')).each(function (item) {
            if (!vatcount[item.vat])
                vatcount[item.vat] = 1;
            else
                vatcount[item.vat]++;
            if (vatcount[item.vat] > maxcount) {
                maxcount = vatcount[item.vat];
                maxVat = item.vat;
            }
        });
        return maxVat;
    },

    summarize: function() {
        // NB: This (javascript) function should be synced with InvoiceSummarizer (java) class in Connect project!

        var summarized = new this.constructor();
        summarized.attributes = $.extend(true, {}, this.attributes);

        // These configuration flags are configurable in connect but there's no GUI for it. If they're made
        // configurable by the user they should be moved to core group configuration.
        var includingProjectTitle = true;
        var usingProjectTitleRow = false;

        var projectIds = [];
        _(summarized.get('items')).each(function (item) {
            if (projectIds.indexOf(item.customerProjectId) < 0) projectIds.push(item.customerProjectId);
        });
        var multipleProjects = projectIds.length > 1;

        function getKey(item) {
            var title = item.title;
            if (!title) title = item.description;
            if (!title && includingProjectTitle) title = item.customerProjectName;
            var key = '';
            if (includingProjectTitle) key += item.customerProjectId + '-';
            key += item.articleId + '-' + title + '-' + item.unit + '-' + item.unitPrice;
            return key;
        }

        function addItemToSummarized(addItem, item) {
            var addInvoicedAmount = addItem.invoicedAmount != null ? addItem.invoicedAmount : addItem.amount;
            var addAmount = addItem.amount;
            var summarizedInvoicedAmount = item.invoicedAmount != null ? item.invoicedAmount : item.amount;
            var summarizedAmount = item.amount;
            if (addInvoicedAmount != null) {
                item.invoicedAmount = summarizedInvoicedAmount != null ? summarizedInvoicedAmount + addInvoicedAmount : addInvoicedAmount;
            }
            if (addAmount != null) item.amount = summarizedAmount != null ? summarizedAmount + addAmount : addAmount;
            if (item.title && item.title != item.description && item.description != addItem.description) item.description = null;

            if (addItem.articleId != item.articleId)
                item.articleId = item.articleNo = null;
            if (addItem.jobId != item.jobId)
                item.jobId = item.jobNo = null;
            if (addItem.workReportId != item.workReportId)
                item.workReportId = null;
            if (addItem.orderId != item.orderId)
                item.orderId = item.orderNo = null;
            if (addItem.assetId != item.assetId)
                item.assetId = item.assetNo = null;
            if (addItem.userId != item.userId)
                item.userId = item.userName = item.userFullName = null;
            if (addItem.vehicleId != item.vehicleId)
                item.vehicleId = item.vehicleName = null;
            return item;
        }

        var summarizedItems = {};
        var items = [];

        _(summarized.get('items')).each(function (item) {
            if (!item.articleId && !item.title && !item.description && item.customerProjectId && !item.amount && !item.invoicedAmount) {
                if (!usingProjectTitleRow)
                    return;
                else if (items.length > 0)
                    items.add({}); // add empty item before project title row
            }

            var key = getKey(item);
            if (key in summarizedItems)
                addItemToSummarized(item, summarizedItems[key]);
            else {
                summarizedItems[key] = item;
                items.push(item);

                if (includingProjectTitle && multipleProjects && !usingProjectTitleRow && item.customerProjectName) {
                    if (item.title && item.title.indexOf(item.customerProjectName) < 0)
                        item.title = item.customerProjectName + ': ' + item.title;
                    else if (item.description && item.description.indexOf(item.customerProjectName) < 0)
                        item.description = item.customerProjectName + ': ' + item.description;
                }

            }
        });
        summarized.set('items', items);
        return summarized;
    },


});

var Invoices = Backbone.Collection.extend({
    model: Invoice,

    url: function() {
        if (this.customUrl)
            return this.customUrl;

        // Build query from filter parameters
        var url;
        var params = { };
        if (this.searchQuery) {
            url = Core.contextRoot + '/invoice/history/search';
            params.q = this.searchQuery;
        } else {
            url = Core.contextRoot + '/invoice' + (this.filterParams.path ? '/' + this.filterParams.path : '');
            if (this.filterParams.from) params.from = this.filterParams.from;
            if (this.filterParams.to) params.to = this.filterParams.to;
            if (this.filterParams.states) params.states = this.filterParams.states.join(',');
            if (this.filterParams.customerIds) params.customers = this.filterParams.customerIds.join(',');
            if (this.filterParams.projectIds) params.projects = this.filterParams.projectIds.join(',');
            if (this.filterParams.activityIds) params.activities = this.filterParams.activityIds.join(',');
            if (this.filterParams.userIds) params.users = this.filterParams.userIds.join(',');
            if (this.filterParams.groupIds) params.groups = this.filterParams.groupIds.join(',');
            if (this.filterParams.limit) params.limit = this.filterParams.limit;
            if (this.filterParams.offset) params.offset = this.filterParams.offset;
            if (this.filterParams.modified) params.modified = this.filterParams.modified;
            if (this.filterParams.notToBeInvoiced) params.nottobeinvoiced = '';
            if (this.filterParams.onlyAttested) params.only_attested = '';
        }
        var paramsStr = $.param(params);
        if (paramsStr.length > 0) url += '?' + paramsStr;
        return url;
    },

    initialize: function(models, options) {
        this.filterParams = {
            path: 'candidate',
            states: ['FINISHED']
        };
        if (options && options.url)
            this.customUrl = options.url;
        else if (options && options.filterParams)
            this.filterParams = options.filterParams;
        else if ((!options || !options.temporary) && ('invoices.filterParams' in sessionStorage))
            this.filterParams = JSON.parse(sessionStorage['invoices.filterParams']);
    },

    saveFilter: function() {
        sessionStorage['invoices.filterParams'] = JSON.stringify(this.filterParams);
    },

    finish: function() {
        var invoices = [];
        this.each(function (model) {
            if (!model.get('finished')) invoices.push(model.toJSON());
        });
        var self = this;
        return $.ajax({
            url: Core.contextRoot + '/invoice',
            type: 'POST',
            data: JSON.stringify(invoices),
            dataType: 'json',
            contentType: 'application/json'
        }).success(function() {
            self.each(function (model) { model.set({finished: true}); });
        });
    },


});
