"use strict";

var ApiConsumer = Backbone.Model.extend({
    urlRoot: Core.contextRoot + '/apiconsumer',
    idAttribute: 'key',

    defaults: function() {
        if (Core.client && Core.client.me) {
            return {
                contactName: Core.client.me.longName(),
                contactEmail: Core.client.me.get('emailAddress'),
                secret: Core.randomUUID()
            };
        }
    },

    isExpired: function() {
        return this.get('expires') && new Date().getTime() > this.get('expires');
    }
});

var ApiConsumers = Backbone.Collection.extend({
    model: ApiConsumer,
    url: Core.contextRoot + '/apiconsumer'
});
