
"use strict";

var Route = Core.ShareableModel.extend({
	urlRoot: Core.contextRoot + '/route',
	editGroupPermission: 'admin_jobs',

	defaultStart: {},
	defaultEnd: {},
	defaults: function() { return {
		destinations: [ _(this.defaultStart).clone(), _(this.defaultEnd).clone() ],
        stateTracked: true
	}; },

	initialize: function() {
	},

    setFromTemplate: function (template, date) {
        var attributes = $.extend(true, {}, template.attributes);
		attributes.fromTemplateId = template.attributes.id;
        delete attributes.title;
        delete attributes.id;
        delete attributes.uuid;
		delete attributes.lastModified;
		delete attributes.creatorId;
		delete attributes.creationTimeStamp;
        delete attributes.startTime;
        delete attributes.template;
        if (attributes.destinations) {
            _(attributes.destinations).each(function (destination) {
                delete destination.id;
            });
            if (this.get('destinations') && this.get('destinations').length > 2) {
                var destinations = [];
                destinations.push(attributes.destinations[0]);
                destinations = destinations.concat(this.get('destinations').splice(1, this.get('destinations').length-2));
                destinations.push(attributes.destinations[attributes.destinations.length-1]);
                attributes.destinations = destinations;
            }
        }

        var current = this.attributes;
        // Don't change group if we don't have permission in that group
        if (!Core.client.hasGroupPermission(attributes.groupId, 'create_jobs')) delete attributes.groupId;
        // Retain current group if template is from parent group
		if (template.isValidForGroup(current.groupId)) delete attributes.groupId;

        this.set(attributes, { silent: true });
        this.setDate(date);
        this.trigger('change');
    },

    // Server operations

	geocode: function() {
		var self = this;
		var destinations = this.get('destinations');
		var geocodedStart = destinations[0].pendingGeocode;
		var geocodedEnd = destinations[destinations.length-1].pendingGeocode;
		return $.ajax({ 
			url: Core.contextRoot + '/routing/geocode',
    		type: 'POST', 
    		data: JSON.stringify(this.toJSON()), 
    		contentType: 'application/json', 
    		success: function(response) {
				// If number of destination changed, reset
    			if (response.destinations.length != destinations.length) {
    				this.set(response, {silent: true});
    			} else {
    				// Otherwise just copy location data so we don't need to re-render it all
    				for (var i = 0; i < response.destinations.length; i++) {
    					var destination = destinations[i];
    					destination.location = response.destinations[i].location;
                        if (response.places && response.places[i])
    					    destination.geocodedPlace = response.places[i];
    					delete destination.pendingGeocode;
    				}
    			}
    		}
		});
	},

    geocodeDestination: function(destination) {
        if (typeof destination == 'number')
            destination = this.get('destinations')[destination];
        if (!destination.location) return;
        return $.getJSON(Core.contextRoot + '/map/search?' + $.param({q: destination.location.placeName}), function (response) {
            if (response.places && response.places.length >= 1) {
                var place = response.places[0];
                console.log(destination.location.placeName + ' => \n' + place.name + ' ' + place.latitude + ' ' + place.longitude + ' - ' + place.type + ' ' + place.source);
                destination.location.latitude = place.latitude;
                destination.location.longitude = place.longitude;
                destination.geocodedPlace = place;
                if (place.type == 'STREET_ADDRESS' || place.type == 'PLACE')
                    destination.geocodeResult = 'ok';
                else
                    destination.geocodeResult = 'warn';
            } else {
                console.log('geocoding failed', location.placeName);
                destination.geocodeResult = 'fail';
            }
            delete destination.pendingGeocode;
        });
    },
	
	reverseGeocodeDestination: function(destination) {
        if (typeof destination == 'number')
            destination = this.get('destinations')[destination];
		var params = $.param({lat: destination.location.latitude, lon: destination.location.longitude});
		return $.getJSON(Core.contextRoot + '/map/search?' + params, function (result) {
			var address = result.places[0].name;
			address = address.replace(', ' + result.places[0].country, '');
			destination.location.placeName = address;
			destination.geocodedPlace = result.places[0];
			destination.geocodedReverse = true;
			destination.pendingGeocode = false;
		});
	},

	optimize: function() {
		var self = this;
		this.set({ optimized: false }, { silent: true });
		return $.ajax({ 
			url: Core.contextRoot + '/routing/optimize',
    		type: 'POST', 
    		data: JSON.stringify(this.toJSON()), 
    		contentType: 'application/json', 
    		success: function(response) {
    			self.set(response, { silent: true });
    			self.set({ optimized: true });
    		}
		});
	},
	
	route: function(options) {
		var self = this;
		this.set({ routed: false }, { silent: true });
		return $.ajax({ 
			url: Core.contextRoot + '/routing/route',
    		type: 'POST', 
    		data: JSON.stringify(this.toJSON()), 
    		contentType: 'application/json', 
    		success: function(response) {
    			self.set(response, { silent: true });
                _(self.get('destinations')).each(function (destination) {
                    delete destination.originalRouteTime;
                    if (destination.routeTime) destination.originalRouteTime = destination.routeTime;
                });
    			self.recalculate(options);
    			self.set({ routed: true }, options);
    		}
		});
	},

    routeDestination: function(toDestination) {
        if (typeof toDestination == 'number')
            toDestination = this.get('destinations')[toDestination];
        var fromDestination = this.get('destinations')[this.get('destinations').indexOf(toDestination)-1];
        var params = {};
        if (this.get('modeOfTransport'))
            params.transport = this.get('modeOfTransport');
        if (this.get('type'))
            params.type = this.get('type');
        if (this.get('timeCorrection'))
            params.timecorrection = this.get('timeCorrection');
        var self = this;
        return $.ajax({
            url: Core.contextRoot + '/routing/leg/route?' + $.param(params),
            type: 'POST',
            data: JSON.stringify([ fromDestination, toDestination ]),
            contentType: 'application/json',
            success: function(leg) {
                _(toDestination).extend(leg[1]);
            }
        });
    },
	
	unschedule: function(userId, sendMessage) {
		var self = this;
		var params = { userId: userId };
		if (sendMessage)
			params.sendMessage = true;
		return $.ajax({
			url: this.urlRoot + '/' + this.id + '/unschedule?' + $.param(params),
			type: 'PUT'
		}).success(function (data) {
			self.set(data);
		});
	},
	
	duplicate: function() {
		var self = this;
		return $.ajax({
			url: this.urlRoot + '?templateId=' + this.id,
			type: 'POST',
			data: JSON.stringify({}), 
    		contentType: 'application/json', 
		}).success(function (data) {
			self.set(data);
		});
	},
	
	schedule: function(userId, sendMessage) {
		var self = this;
		var params = { userId: userId };
		if (sendMessage)
			params.sendMessage = true;
		return $.ajax({
			url: this.urlRoot + '/' + this.id + '/schedule?' + $.param(params),
			type: 'PUT'
		}).success(function (data) {
			self.set(data);
		});
	},
	
	assign: function(userId, sendMessage) {
		var self = this;
		var params = { userId: userId };
		if (sendMessage)
			params.sendMessage = true;
		return $.ajax({
			url: this.urlRoot + '/' + this.id + '/assign?' + $.param(params),
			type: 'PUT'
		}).success(function (data) {
			self.set(data);
		});
	},
	
	overrideState: function(state) {
		var self = this;
		return $.ajax({
			url: this.urlRoot + '/' + this.id + '/state/' + state,
			type: 'PUT'
		}).success(function (data) {
			self.set(data);
		});
	},
	
	reportUserState: function(userId, state) {
		var self = this;
		return $.ajax({
			url: this.urlRoot + '/' + this.id + '/user/' + userId + '/state/' + state,
			type: 'PUT'
		}).success(function (data) {
			self.set(data);
		});
	},

    scheduleVehicle: function(vehicleId) {
        var self = this;
        var params = { vehicleId: vehicleId };
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/schedule?' + $.param(params),
            type: 'PUT'
        }).success(function (data) {
            self.set(data);
        });
    },

    unscheduleVehicle: function(vehicleId) {
        var self = this;
        var params = { vehicleId: vehicleId };
        return $.ajax({
            url: this.urlRoot + '/' + this.id + '/unschedule?' + $.param(params) ,
            type: 'PUT'
        }).success(function (data) {
            self.set(data);
        });
    },

    // Utility
	
	recalculate: function(options) {
        if (!options) options = {};
		if (!this.get('destinations'))
			return;
        console.log('recalculate');
		// Clear route time, sum stop duration
		var totalStopDuration = 0;
		var totalTime = 0;
		var totalDistance = 0;
		var routed = true;
		for (var i = 0; i < this.get('destinations').length; i++) {
			var destination = this.get('destinations')[i];
			if (!destination)
				continue;

			// Apply time correction multiplier
			if (this.get('timeCorrection') && 'routeTime' in destination && this.originalTimeCorrection) {
				if ('originalRouteTime' in destination)
					destination.routeTime = destination.originalRouteTime * this.get('timeCorrection') / this.orginalTimeCorrection;
			}

			destination.calculatedArrivalTime = null;
			delete destination.late;
			if (destination.stopDuration)
				totalStopDuration += destination.stopDuration;
			if (typeof destination.routeTime == 'number')
				totalTime += destination.routeTime;
			else if (i > 0)
				routed = false;
			if (typeof destination.routeDistance == 'number')
				totalDistance += destination.routeDistance;
			else if (i > 0)
				routed = false;
		}
        var attributes = {}
		if (totalStopDuration != this.get('stopDuration'))
            attributes.totalStopDuration = totalStopDuration;
		attributes.totalTime = routed ? totalTime : null;
		attributes.totalDistance = routed ? totalDistance : null;
        attributes.routed = routed;
        this.set(attributes, {silent: options.silent});

		if (routed) {
			
			var time = null;
			var firstArrivalIndex = -1;
			// Find first destination with arrival time
			for (var i = 0; i < this.get('destinations').length; i++) {
				var destination = this.get('destinations')[i];
				if (destination.arrivalTime) {
					destination.calculatedArrivalTime = destination.arrivalTime;
					firstArrivalIndex = i;
					break;
				}
			}
            var allDay = this.get('startTime') && new Date(this.get('startTime')).toString('HHmmss') == '000000';
            if (this.get('startTime') && firstArrivalIndex < 0 && this.get('destinations').length > 1 && !allDay) {
                firstArrivalIndex = 1;
                this.get('destinations')[firstArrivalIndex].calculatedArrivalTime = this.get('startTime');
            }
			if (firstArrivalIndex >= 0) {
				// Step backward from first arrival time
				time = this.get('destinations')[firstArrivalIndex].calculatedArrivalTime;
				for (var i = firstArrivalIndex; i > 0; i--) {
					var destination = this.get('destinations')[i];
					if (destination.routeTime)
						time -= destination.routeTime*1000;
					var prevDestination = this.get('destinations')[i-1];
                    if (options.jobs && prevDestination.jobId) {
                        var job = options.jobs.get(prevDestination.jobId);
                        if (job && job.get('endTime') && job.get('endTime') < time)
                            time = job.get('endTime');
                    }
					if (prevDestination.stopDuration)
						time -= prevDestination.stopDuration*60000;
					prevDestination.calculatedArrivalTime = time;
				}
				// Step forward from first arrival tim

				for (var i = firstArrivalIndex; i < this.get('destinations').length; i++) {
					var destination = this.get('destinations')[i];
					if (!destination)
						continue;
					if (destination.routeTime)
						time += destination.routeTime*1000;
					if (destination.arrivalTime) {
						if (time > destination.arrivalTime) {
                            console.log("Can't make arrival time at " + i);
                            destination.late = true;
						} else
							time = destination.arrivalTime;
					} else {
                        if (options.jobs && destination.jobId) {
                            var job = options.jobs.get(destination.jobId);
                            if (job && job.get('startTime') && !job.get('allDay') && job.get('startTime') > time && !Core.client.groupHasConfiguration(job.get('groupId'), 'route_updates_job_time'))
                                 time = job.get('startTime');
                        }

                    }

					destination.calculatedArrivalTime = time;
					if (destination.stopDuration)
						time += destination.stopDuration*60000;

				}
			}
		}
	},

	clearRoute: function(options) {
		this.wasRoutedBeforeCleared = this.get('totalTime');
		_(this.get('destinations')).each(function (destination) { delete destination.routeData; delete destination.routeTime; delete destination.routeDistance; delete destination.originalRouteTime; });
		this.clearTotals(options);
	},

    clearDestinationRoute: function(destination) {
        if (typeof destination == 'number')
            destination = this.get('destinations')[destination];
        if (!destination) return;

        delete destination.routeData;
        delete destination.routeTime;
        delete destination.routeDistance;
        var index = this.get('destinations').indexOf(destination);
        if (index >= 0 && index < this.get('destinations').length-1) {
            var nextDestination = this.get('destinations')[index+1];
            delete nextDestination.routeData;
            delete nextDestination.routeTime;
            delete nextDestination.routeDistance;
        }
    },

    clearGeocode: function() {
        _(this.get('destinations')).each(function (destination) { delete destination.location.latitude; delete destination.location.longitude; });
        this.set('destinations', this.get('destinations'));
    },

    clearTotals: function(options) {
		this.set({
			totalTime: null,
			totalDistance: null,
			straightLineDistance: null
		}, options);
	},

	removeDestination: function(destination) {
		var destinations = this.get('destinations');
		var index = destinations.indexOf(destination);
		if (index >= 0) {
			console.log('remove destination');
			destinations.splice(index, 1);
			if (destination.jobId) {
				this.set('destinations',_(destinations).reject(function (d) { return d.jobId == destination.jobId; }));
			}
            this.clearDestinationRoute(index);
		} else
			console.error('destination not found', destination, destinations);
	},
	
	setDate: function(newDate) {
        console.log('set date', newDate);
        if (!newDate) {
            this.set({ startTime: newDate }, { silent: true });
            return;
        }

		var destinations = this.get('destinations');

        // Hard reset first and last destinations
        _([ destinations[0], destinations[destinations.length-1] ]).each(function (destination) {
            if (destination.arrivalTime) {
                var date = new Date(destination.arrivalTime);
                date.setFullYear(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
                destination.arrivalTime = date.getTime();
            }
            if (destination.calculatedArrivalTime) {
                var date = new Date(destination.calculatedArrivalTime);
                date.setFullYear(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
                destination.calculatedArrivalTime = date.getTime();
            }
        });

		// Find a reference (old) date
        var oldDate;
        var firstArrivalTime;
        for (var i = 1; i < destinations.length-2; i++) {
            var d = destinations[i];
            if (d.arrivalTime && (!firstArrivalTime || d.arrivalTime < firstArrivalTime)) firstArrivalTime = d.arrivalTime;
        }
		if (!firstArrivalTime) {
            this.set({ startTime: newDate.getTime() }, { silent: true });
			return;
        }
        if (firstArrivalTime) {
            oldDate = new Date(firstArrivalTime);
        }
        if (!oldDate) {
            this.set({ startTime: newDate.getTime() }, { silent: true });
            return;
        }
        oldDate.setHours(0,0,0);
		// Figure out the difference in days
		var diffDays = Math.floor((newDate.getTime() - oldDate.getTime())/(24*60*60000));
        console.log('diff',diffDays);
		if (!diffDays)
			return;
		// Adjust all arrival times
		_(destinations).each(function (destination) {
			if (destination.arrivalTime)
				destination.arrivalTime = new Date(destination.arrivalTime).addDays(diffDays).getTime();
			if (destination.calculatedArrivalTime)
				destination.calculatedArrivalTime = new Date(destination.calculatedArrivalTime).addDays(diffDays).getTime();
		});
        if (destinations[0].calculatedArrivalTime)
            this.set({ startTime: destinations[0].calculatedArrivalTime }, { silent: true });
        else if (destinations[0].arrivalTime)
            this.set({ startTime: destinations[0].arrivalTime }, { silent: true });
        else
            this.set({ startTime: newDate.getTime() }, { silent: true });
        console.log('startTime after',new Date(this.get('startTime')));
	},

    follow: function(follower) {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.get('uuid') + '/follower/me',
            type: 'PUT',
            contentType: 'application/json',
            data: JSON.stringify(follower),
        }).success(function (data) {
            self.set(data, {silent: true});
        });
    },

    unfollow: function() {
        var self = this;
        return $.ajax({
            url: this.urlRoot + '/' + this.get('uuid') + '/follower/me',
            type: 'DELETE'
        }).success(function (data) {
            self.set(data, {silent: true});
        });
    },

    // Getters

	isDispatchable: function() {
		if (this.get('groupId'))
			return Core.client.hasGroupPermission(this.get('groupId'), 'admin_jobs');
		else
			return this.isEditable();
	},
	
	isPublished: function() {
		var state = this.get('state');
		return (state && state != 'DRAFT' && state != 'CLOSED');
	},
	
	isActive: function() {
		var state = this.get('state');
		return (state == 'IN_TRANSIT' || state == 'ON_STATION' || state == 'STARTED' || state == 'PAUSED');
	},
	
	isLate: function() {
		var state = this.get('state');
		if (state == 'INITIAL' || state == 'DRAFT' || state == 'FINISHED' || state == 'CANCELED' || state == 'CLOSED')
			return false;
        var allDay = this.get('startTime') && new Date(this.get('startTime')).toString('HHmmss') == '000000';
		if (this.isActive())
			return false;
		if (this.get('endTime') && new Date().getTime() > this.get('endTime'))
			return true;
		if (this.get('startTime') && new Date().getTime() > this.get('startTime') + (allDay ? 24*3600000 : 0))
			return true;
		return false;
	},
	
	isResourceScheduled: function(resource) {
		var state = resource.state;
		return resource.userId && state != 'UNASSIGNED' && state != 'ASSIGNED' && state != 'DECLINED';
	},
	
	isResourceLate: function(resource) {
		var state = resource.state;
		if (state == 'FINISHED' || state == 'CANCELED')
			return false;
		if (state == 'IN_TRANSIT' || state == 'ON_STATION' || state == 'STARTED' || state == 'PAUSED')
			return false;
        var allDay = this.get('startTime') && new Date(this.get('startTime')).toString('HHmmss') == '000000';
		if (this.get('endTime') && new Date().getTime() > this.get('endTime'))
			return true;
		if (this.get('startTime') && new Date().getTime() > this.get('startTime') + (allDay ? 24*3600000 : 0))
			return true;
		return false;
	},
	
	getResourceId: function(resourceId) {
		return _(this.get('resources')).find(function (resource) { return resource.id == resourceId; });
	},
	
	getResource: function(userId) {
		return _(this.get('resources')).find(function (resource) { return resource.userId == userId; });
	},

    getResourceByVehicle: function(vehicleId) {
        return _(this.get('resources')).find(function (resource) { return resource.vehicleId == vehicleId; });
    },

    myResource: function() {
		return this.getResource(Core.client.me.id);
	},
	
	canScheduleMe: function() {
		if (!this.myResource() && this.isPublished() && (this.get('selfScheduled') || this.isDispatchable() || this.get('creatorId') == Core.client.me.id)) {
			if (this.get('groupId') && !Core.client.hasGroupPermission(this.get('groupId'), 'do_work'))
				return false;
			if (this.get('autoAddResources') || this.getFreeResource())
				return true;
		}
		return false;
	},
	
	canScheduleOthers: function() {
		if (this.isDispatchable() && this.isPublished()) {
			if (this.get('autoAddResources') || this.getFreeResource())
				return true;
		}
		return false;
	},
	
	getFreeResource: function() {
		return _(this.get('resources')).find(function (resource) { return !resource.userId || resource.state == 'DECLINED'; });
	},

	getFirstDestinationAtLocation: function(location) {
		if (!location)
			return undefined;
		var destinations = this.get('destinations');
		for (var i = 0; i < destinations.length; i++) {
			var destination = destinations[i];
			if (destination.location && Math.abs(destination.location.latitude - location.latitude) < 1e-6 && Math.abs(destination.location.longitude - location.longitude) < 1e-6)
				return destination;
		}
	},
	
	getDestination: function(destinationId) {
		return _(this.get('destinations')).find(function (destination) { return destination.id == destinationId; });
	},
	
	getDestinationsByJob: function(job) {
		var jobId = job;
		if (typeof job == 'object')
			jobId = job.id;
		return _(this.get('destinations')).select(function (destination) { return destination.jobId == jobId; });
	},

    hasStartAndStop: function() {
        var destinations = this.get('destinations');
        if (!destinations || destinations.length < 2) return false;
        var last = destinations.length-1;
        if (!destinations[0].location || (!destinations[0].location.placeName && !destinations[0].location.latitude)) return false;
        if (!destinations[last].location || (!destinations[last].location.placeName && !destinations[last].location.latitude)) return false;
        return true;
    },

    hasArrivalTime: function() {
        var destinations = this.get('destinations');
        if (!destinations || destinations.length == 0) return false;
        for (var i = 0; i < destinations.length; i++)
            if (destinations[i].arrivalTime) return true;
        return false;
    },

	hasJobs: function() {
        var destinations = this.get('destinations');
        if (!destinations || destinations.length == 0) return false;
        for (var i = 0; i < destinations.length; i++)
            if (destinations[i].jobId) return true;
        return false;
	},
    getJobIds: function() {
        var destinations = this.get('destinations');
        if (!destinations || destinations.length == 0) return []
        var jobIds = [];
        for (var i = 0; i < destinations.length; i++)
            if (destinations[i].jobId) jobIds.push(destinations[i].jobId);
        return jobIds;
    },
	workDuration: function() { return this.get('totalTime') ? this.get('totalTime') / 60 + this.get('totalStopDuration') : undefined; },
	slackDuration: function() {
		if (this.get('totalTime')) {
			var lastDestination = this.get('destinations')[this.get('destinations').length-1];
			var firstDestination = this.get('destinations')[0];
			if (firstDestination && lastDestination && firstDestination.calculatedArrivalTime && lastDestination.calculatedArrivalTime)
				return Math.round((lastDestination.calculatedArrivalTime - firstDestination.calculatedArrivalTime)/60000 - this.get('totalTime')/60) - this.get('totalStopDuration');
		}
	},
	anyGeocoded: function() {
		var destinations = this.get('destinations');
		for (var i = 0; i < destinations.length; i++) {
			var destination = destinations[i];
			if (destination.location && destination.location.latitude)
				return true;
		}
		return false;
	},
	needsGeocode: function() {
		var needsIt = false;
		_(this.get('destinations')).each(function (destination) {
			if (destination.location && destination.location.placeName && (!destination.location.latitude || !destination.location.longitude)) {
				needsIt = true;
				return;
			}
		}, this);
		return needsIt;
	},
    anyRouted: function() {
        var destinations = this.get('destinations');
        for (var i = 1; i < destinations.length-1; i++) {
            var destination = destinations[i];
            if (destination.routeData)
                return true;
        }
        return false;
    },
    allRouted: function() {
        var destinations = this.get('destinations');
        for (var i = 1; i < destinations.length-1; i++) {
            var destination = destinations[i];
            if (!destination.routeData)
                return false;
        }
        return true;
    },
	titleExtended: function() {
		if (this.get('title'))
			return this.get('title');
		else {
			var title = '';
			var destinations = this.get('destinations');
			if (destinations.length > 2 && destinations[1].location && destinations[1].location.placeName)
				title += destinations[1].location.placeName.replace(/[\n,].*/,'');
			if (destinations.length > 3)
				title += ' (+' + (destinations.length-3) + ')';
			return title.trim();
		}
	},

    // Time summary getters

    getTotalTimeFromStartToEndDestination: function() {
        var firstDestination = this.get('destinations')[0];
        var lastDestination = this.get('destinations')[this.get('destinations').length-1];
        return lastDestination.calculatedArrivalTime - firstDestination.calculatedArrivalTime;
    },

    getTotalTimeFromStartToFinishEvent: function() {
        // Total active time = finish - start - paused
        var finish = this.getFinishEvent();
        var start = this.getStartEvent();
        if (!finish || !start)
            return;
        return finish.eventTimeStamp - start.eventTimeStamp;
    },

    sumTimeByState: function(state, destinationId) {
        var events = this.getResourceActionEvents();
        if (!events) return 0;
        var stateTime = 0;
        var currState;
        var lastEventTimeStamp;
        for (var i = 0; i < events.length; i++) {
            var event = events[i];
            if (currState == state || (state == 'ACTIVE' && currState != 'PAUSED' && currState != 'FINISHED' && currState != 'CANCELED' && currState)) {
                var addTime = event.eventTimeStamp - lastEventTimeStamp;
                stateTime += addTime;
            }
            if (!destinationId || event.destinationId == destinationId)
                currState = event.newResourceState;
            else
                currState = null;
            lastEventTimeStamp = event.eventTimeStamp;
        }
        return stateTime;
    },
    getResourceActionEvents: function(resource) {
        if (!this.get('events')) return;
        return _(this.get('events')).select(function (event) { return event.type == 'RESOURCE_ACTION' && (!resource || resource.userId == event.userId || resource.userId == event.toUserId); });
    },
    getStartEvent: function() {
        if (!this.get('events')) return;
        return _(this.getResourceActionEvents()).first();
    },
    getFinishEvent: function() {
        if (!this.get('events')) return;
        return _(_(this.get('events')).select(function (event) { return event.type == 'RESOURCE_ACTION' && event.newResourceState == 'FINISHED'; })).last();
    },
    getEventsByState: function(state, destinationId) {
        if (!this.get('events')) return [];
        return _(this.get('events')).select(function (event) { return (!destinationId || event.destinationId == destinationId) && event.type == 'RESOURCE_ACTION' && event.newResourceState == state; });
    },
    findNearestEventForState: function(state, timeStamp, destinationId) {
        var events = this.getEventsByState(state, destinationId);
        if (events.length > 0) {
            var mindiff = Number.MAX_VALUE;
            var closestEvent;
            _(events).each(function(event) {
                var diff = Math.abs(timeStamp - event.eventTimeStamp);
                if (diff < mindiff) {
                    mindiff = diff;
                    closestEvent = event;
                }
            }, this);
            return closestEvent;
        }

    },
    myFollower: function() { return _(this.get('followers')).findWhere({userId: Core.client.me.id}); },
    myFollowerOptions: function() {
        var myOptions = [];
        if (!Core.client.groups || !this.get('groupId')) return null;
        var group = Core.client.groups.get(this.get('groupId'));
        if (!group) return null;
        var myFollower = this.myFollower();
        if (myFollower) {
            if (myFollower.options != null)
                return myFollower.options;
            else
                return ['states', 'discrepancies']; // Follower.DEFAULT_OPTIONS
        }
        var groupOptions = group.get('followerOptions');
        if (groupOptions['routeCreator'] && Core.client.me.id == this.get('creatorId')) {
            var options = groupOptions['routeCreator'];
            if (myOptions.length < options.length) myOptions = options;
        }
        if (groupOptions['routeResource'] && this.myResource()) {
            var options = groupOptions['routeResource'];
            if (myOptions.length < options.length) myOptions = options;
        }
        if (groupOptions['routeDispatcher'] && _(this.get('resources')).findWhere({dispatcherId: Core.client.me.id})) {
            var options = groupOptions['routeDispatcher'];
            if (myOptions.length < options.length) myOptions = options;
        }
        return _(myOptions).uniq();
    },

});

var Routes = Backbone.Collection.extend({
	model: Route,

	url: function() {

        var url;
        var params = { };

        if (this.filterParams.states) {
            if (typeof this.filterParams.states == 'object')
                params.states = this.filterParams.states.join(',');
            else
                param.states = this.filterParams.states;
        } else {
            if (this.filterParams.includeFinished) params.finished = '';
            if (this.filterParams.includeCanceled) params.canceled = '';
            if (this.filterParams.includeDrafts) params.drafts = '';
            if (this.filterParams.includeClosed) params.closed = '';
        }
        if (this.filterParams.limit) params.limit = this.filterParams.limit;

        if (this.searchQuery) {
            url = Core.contextRoot + '/route/search';
            params.q = this.searchQuery;
        } else {
            // Build query from filter parameters
            url = Core.contextRoot + '/route/list' + (this.filterParams.path ? '/' + this.filterParams.path : '');

            if (this.filterParams.path == 'templates') {
                url = Core.contextRoot + '/route/templates';
                if (this.filterParams.includeDrafts) params.drafts = true;
                if (this.filterParams.onlyEditable) params.only_editable = true;
            } else {
                if (this.filterParams.includeUntimed) params.untimed = '';
				if (this.filterParams.from == null || typeof this.filterParams.from == 'undefined') {
                    var fromDate = new Date();
                    fromDate.setHours(0,0,0,0);
                    this.filterParams.from = fromDate.getTime();
                }
                params.from = this.filterParams.from;
                if (this.filterParams.to)
                    params.to = this.filterParams.to;
                if ('includeCurrent' in this.filterParams) {
                    if (this.filterParams.includeCurrent)
                        params.current = '';
                } else if (!this.filterParams.from && !this.filterParams.to)
                    params.current = '';
            }
        }
		var paramsStr = $.param(params);
		if (paramsStr.length > 0) url += '?' + paramsStr;
		return url;
	},

	initialize: function(options) {
		this.filterParams = {
            path: 'all',
            states: ['DRAFT','PUBLISHED','ASSIGNED','SCHEDULED','ACCEPTED','IN_TRANSIT','ON_STATION','STARTED','PAUSED','DAY_FINISHED','FINISHED','CANCELED'],
            includeCurrent: true,
            includeUntimed: true,
            from: null,
            to: null,
            userId: null
		};
		if (options && options.url)
			this.customUrl = options.url;
		else if (options && options.filterParams)
			_(this.filterParams).extend(options.filterParams);
		else if ('routes.filterParams' in sessionStorage)
			this.filterParams = JSON.parse(sessionStorage['routes.filterParams']);
		if (! 'path' in this.filterParams)
			this.filterParams.path = 'all';
	},

	saveFilter: function() {
		// Save filter parameters in sessionStorage
		sessionStorage['routes.filterParams'] = JSON.stringify(this.filterParams);
		sessionStorage['routes.list'] = this.filterParams.path;
	},

	forEachRelevantTemplate: function(callback, context) {
		var limitToPrimaryGroup = Core.client.me.get('shareAllWithPrimaryGroup') && this.filter(function (template) {
				return template.isValidForGroup(Core.client.me.get('primaryGroupId')) && Core.client.hasPermissionInGroupOrDescendant(template.get('groupId'), 'create_jobs');
			}).length > 0;
		return this.each(function (template) {
			var defaultTemplate = Core.client.primaryGroup() && Core.client.primaryGroup().getOrGetInParentGroup('defaultRouteTemplateId') == template.id;
			if (template.get('groupId') && !Core.client.hasPermissionInGroupOrDescendant(template.get('groupId'), 'create_jobs')) return;
			if (limitToPrimaryGroup && !template.isValidForGroup(Core.client.me.get('primaryGroupId'))) return;
			callback.apply(context, [template, defaultTemplate]);
		});
	}

});

